 html,
 body {
     width: 100%;
     height: 100%;
 }
 
 *,
 *::before,
 *::after {
     box-sizing: border-box;
 }
 
 html {
     font-family: sans-serif;
     line-height: 1.15;
     -webkit-text-size-adjust: 100%;
     -ms-text-size-adjust: 100%;
     -ms-overflow-style: scrollbar;
     -webkit-tap-highlight-color: rgba(26, 54, 64, 0);
 }
 
 body {
     margin: 0;
     color: #222;
     font-size: 14px;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
     font-variant: tabular-nums;
     line-height: 1.5715;
     background-color: #f7f9fc;
     font-feature-settings: 'tnum';
 }